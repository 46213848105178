import React from 'react'
import styled from 'styled-components'

const FooterStyle = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Footer = () => {
  return <FooterStyle>© Copyright 2022 by Zerg</FooterStyle>
}

export default Footer
